const logotext = "ADI";
const copyright = "Nugraha Adi S";
const meta = {
    title: "Nugraha Adi S | CV & PORTOFOLIO",
    description: "I’m Nugraha Adi S as Full stack and CMS developer,currently working in Jakarta",
};

const introdata = {
    title: "I’m Nugraha Adi Santoso",
    animated: {
        first: "I love building website",
        second: "I made cool websites",
        third: "I develop and design web apps",
    },
    description: "I am currently a Bachelor of Computer Science at Gadjah Mada University. I am someone who has an interest in website development such as WordPress development, Shopify development, Full-Stack development, Front End development, and SEO specialist. Besides that, I also have an interest in graphic design and UI and UX design",
    your_img_url: "https://i.ibb.co/cr9QHsw/adi.jpg",
};

const dataabout = {
    title: "Abit About me ",
    aboutme: "I am currently a Bachelor of Computer Science at Gadjah Mada University. I am someone who has an interest in website development such as WordPress development, Shopify development, Full-Stack development, Front End development, and SEO specialist. Besides that, I also have an interest in graphic design and UI and UX design. I become competent in the field that I mentioned above. Currently, I work more in the world of developing several web-based information applications and designing applications and website designs without neglecting my other interests to continue learning them.",
};
const worktimeline = [{
    jobtitle: "Web Developer",
    where: "Perkumpulan Gerakan OK OCE",
    employment: "Internship",
    date: "Jan 2019 - Mar 2019",
},
{
    jobtitle: "LMS Devloper",
    where: "ArkaLearn (PT. Arka Spektrum Solutindo)",
    employment: "Contract",
    date: "Sep 2020 - Jan 2021",
},
{
    jobtitle: "Wordpress Developer",
    where: "PT. Inbio Tani Nusantara",
    employment: "Contract",
    date: "Aug 2020 - Mar 2021",
},
{
    jobtitle: "Web Developer",
    where: "PT. Redo Marketing Indoneisa",
    employment: "Freelance",
    date: "Feb 2021 - May 2021",
},
{
    jobtitle: "Web Developer",
    where: "Javara Indoneisa (PT. Kampung Kearifan Indoneisa)",
    employment: "Full-time",
    date: "Apr 2021 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Lifetime Design",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Lifetime Design Furniture",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Nodes Studio",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Pixl",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Hoire",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Chante",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
{
    jobtitle: "Web Developer",
    where: "Chante Essentia",
    employment: "Part-time",
    date: "Apr 2023 - Present",
},
];

const skills = [{
    name: "HTML & CSS",
    value: 90,
},
{
    name: "Javascript",
    value: 80,
},
{
    name: "Node JS",
    value: 80,
},
{
    name: "React JS",
    value: 80,
},
{
    name: "Next JS",
    value: 80,
},
{
    name: "Wordpress",
    value: 95,
},
{
    name: "Shopify",
    value: 90,
},
{
    name: "Adobe Illustration",
    value: 85,
},
{
    name: "Adobe XD",
    value: 85,
},
{
    name: "Adobe Photoshop",
    value: 80,
},
{
    name: "Yoast SEO",
    value: 85,
},
];

const services = [{
    title: "Wordpress Developer",
    description: "In the areas of WordPress website development expertise include: Web Design & Information Architecture. Custom Theme Coding (HTML, CSS, jQuery/JavaScript) Mobile Compatibility/Responsive Web Design.",
},
{
    title: "Shopify Developer",
    description: "Shopify developers are software engineers who build eCommerce websites using the Shopify platform.",
},
{
    title: "SEO Specialist",
    description: "SEO stands for “search engine optimization.” In simple terms, it means the process of improving your site to increase its visibility when people search for products or services related to your business in Google, Bing, and other search engines.",
},
{
    title: "UI & UX Design",
    description: "UI / UX service focusing on finding insight on your customer while developing the ... Creating a good experience can be a bright future of your business.",
},
{
    title: "Frontend Developer",
    description: "Front-End Developer is responsible for developing new user-facing features, determining the structure and design of web pages, building reusable codes, optimizing page loading times, and using a variety of markup languages to create the web pages.",
},
{
    title: "Full Stack Developer",
    description: "Full Stack refers to the development of both front end(client side) and back end(server side) portions of web application. Full stack web Developers: Full stack web developers have the ability to design complete web applications and website",
},
];

const dataportfolio = [{
    titleCompany: "Redo Marketing Indonesia",
    img: "https://i.ibb.co/QDdCXF3/Screen-Shot-2022-12-27-at-3-44-52-PM.png",
    description: "PT. Redo Marketing Indonesia was established in 2014, specializing in Near Infrared Technology, Silkpeel Dermalinfusion and Lumixyl Brightening product.",
    link: "https://redo.co.id/",
},
{
    titleCompany: "Inbio Tani Nusantara",
    img: "https://i.ibb.co/Hqtyx3J/Screen-Shot-2022-12-27-at-5-29-11-PM.png",
    description: "Latar belakang terbentuknya PT. Inbio Tani Nusantara dan produk LOB (Liquid Organic Biofertilizer) berawal dari kepedulian PT. Great Giant Pineapple terhadap lingkungan dan produk organik yang ramah lingkungan dan mendukung nilai sustainable agriculture",
    link: "https://inbiotaninusantara.com/",
},
{
    titleCompany: "ArkaLearn",
    img: "https://i.ibb.co/KmFrscb/Screen-Shot-2023-01-02-at-4-16-55-PM.png",
    desctiption: "ArkaLearn adalah Startup EduTech karya anak bangsa Indonesia yang dibangun untuk membantu calon Pekerja Migran Indonesia menjadi terampil dan mendapatkan pekerjaan yang sesuai dengan memberikan pelatihan yang berkualitas dan program kerja jalur mandiri/perseorangan dengan proses dan biaya yang transparan.",
    link: "https://arkalearn.com/",
},
{
    titleCompany: "Javara E-Store",
    img: "https://i.ibb.co/80tLRwT/Screen-Shot-2023-01-02-at-4-24-26-PM.png",
    description: "Javara adalah brand dari PT. Kampung Kearifan Indonesia, perusahaan yang bekerja di seluruh mata rantai pasokan makanan untuk menghidupkan kembali pangan terlupakan warisan nusantara. Melalui kemitraan dengan para petani, perimba, nelayan dan artisan pangan, Javara menghadirkan beragam produk pangan premium dan organik dari berbagai pelosok Indonesia ke pasar nasional dan internasional.",
    link: "https://javarastore.com/",
},
{
    titleCompany: "Javara Indonesia",
    img: "https://i.ibb.co/syzSbF8/Screen-Shot-2023-01-02-at-4-23-34-PM.png",
    description: "Javara is the flagship of PT Kampung Kearifan Indonesia, a company that works across the supply chain to sustain Indonesia’s forgotten food biodiversity heritage by delivering it to the national and international market.",
    link: "https://javarastore.com/",
},
{
    titleCompany: "Lifetime Design",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/lifetimedesign.co__1.png?v=1714025238",
    description: "Lifetime Design merupakan usaha yang bergerak dalam bidang furniture dan interior design yang telah beroperasi dan berkembang sejak tahun 2007.",
    link: "https://lifetimedesign.co/",
},
{
    titleCompany: "Lifetime Design Furniture (E-Commerce)",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/lifetimedesign.co_pages_furniture_1.png?v=1714025501",
    description: "Lifetime Design merupakan usaha yang bergerak dalam bidang furniture dan interior design yang telah beroperasi dan berkembang sejak tahun 2007.",
    link: "https://lifetimedesign.co/pages/furniture",
},
{
    titleCompany: "Nodes Studio",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/www.nodesstudio.com.png?v=1714024919",
    description: "Nodes Studio adalah Konsultan desain interior dan Arsitektur dengan specialist desain kontemporer, modern classic, dan minimalis.",
    link: "https://nodesstudio.com/",
},
{
    titleCompany: "Pixl",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/pixl.co.id.png?v=1714024661",
    description: "PIXL is 3D Architectural & Interior Visualization Company in Indonesia.",
    link: "https://pixl.co.id/",
},
{
    titleCompany: "Hoire",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/hoire.co.png?v=1714025689",
    description: "Hoire merupakan kependekan dari Hospitality, Office, Industrial dan Retail.",
    link: "https://pixl.co.id/",
},
{
    titleCompany: "Chante Fashion (E-Commerce)",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/chante.id__3.png?v=1714025907",
    description: "Semua produk Chanté dirancang dengan warna-warna netral yang menenangkan dan desain yang elegan nan anggun.",
    link: "https://chante.id",
},
{
    titleCompany: "Chante Essentia (E-Commerce & Distribution)",
    img: "https://cdn.shopify.com/s/files/1/0804/5677/8011/files/chanteessentia.id.png?v=1714025955",
    description: "At Chante Essentia, we believe in the transformative power of nature's ingredients, and our mission is to deliver skincare solutions that nourish your skin from within.",
    link: "https://chanteessentia.id",
},
];

const contactConfig = {
    YOUR_EMAIL: "nugrahaadisantoso@yahoo.com",
    YOUR_FONE: "+62-8564-1818-823",
    LINK_PHONE: "https://wa.me/6285641818823",
    YOUR_ADDRESS: "Jl. Boulevard Grand Depok City, Tirtajaya, Sukmajaya, Depok City, West Java 16412",
    LINK_ADDRESS: "https://maps.app.goo.gl/CPr5dLxoT8x1Stop9",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_mvtxkqr",
    YOUR_TEMPLATE_ID: "template_b4xf66k",
    YOUR_USER_ID: "7MsGyUQAqryWWCAW0",
};

const socialprofils = {
    github: "https://github.com/nugrahaadis12",
    facebook: "https://id-id.facebook.com/nugraha.adi.792",
    linkedin: "https://id.linkedin.com/in/nugraha-adi-santoso-408538103",
    twitter: "https://twitter.com/nugrahaadi12",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
    copyright,
};
